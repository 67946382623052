.events {
  width: 100%;
  height: 100%;
  position: relative;
}
.events::after {
  content: '';
  position: absolute;
  /* border: 3px solid red; */
  width: 100%;
  height: 40px;
  top: 114%;
  z-index: -1;
  background: #342b3f;
}
.events-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  text-align: center;
  font-size: 4.5rem;
  background: -webkit-linear-gradient(#ff5148, #ad201a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3vw;
  text-transform: uppercase;
}
.event-showcase-text {
  color: #ad201a;
  text-align: center;
  width: 50vw;
  margin: -1vw auto;
  font-size: 1vw;
  margin-top: 1.6vw;
}
.events-slider {
  margin: 5vw 7vw 2vw 7vw;
  text-align: center;
  font-family: 'Metropolis';
}
.events-slider .test {
  width: 20vw;
}
.slick-track {
  padding: 2vw 0vw;
  padding-top: 0;
}
.slick-prev {
  left: -5vw !important;
}
.slick-next {
  right: -3.7vw !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 2.5vw !important;
  line-height: 1;
  opacity: 1 !important;
  color: #ff5148 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.event-info {
  width: 20vw !important;
  border-radius: 2vw;
  position: relative;
  box-shadow: 0px 10px 10px rgba(128, 128, 128, 0.616);
  user-select: none;
}
.event-info .event-img {
  border-radius: 2vw;
  width: 18vw;
  height: 20vw;
  object-fit: cover;
  margin: 0.8vw auto;
}
.event-name {
  font-size: 1.2vw;
  font-weight: 900;
  font-family: 'Metropolis';
}
/* .covid {
  box-shadow: 0px 10px 30px pink;
}
.FinTech {
  box-shadow: 0px 10px 10px rgba(0, 174, 255, 0.373);
} */
@media only screen and (max-width: 768px) {
  .event-info {
    width: 25vw !important;
  }
  .event-info .event-img {
    width: 22vw;
    height: 24vw;
  }

  .event-showcase-text {
    font-size: 1.6vw;
    width: 54vw;
  }
  .slick-track {
    padding: 3vw 0vw;
    padding-top: 0;
  }
  .event-name {
    font-size: 1.6vw;
    margin-bottom: 9px;
  }
}
/* image overlay */
.event-info .img-wrapper {
  position: relative;
  overflow: hidden;
}
.event-info .img-wrapper > img {
  display: block;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
}
.event-info .img-wrapper > .content {
  position: absolute;
  inset: 0;
  font-size: 1.2vw;
  padding: 1rem 3rem;
  background-color: rgba(255, 255, 255, 0.94);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  text-align: left;
  text-transform: none;
}
.event-info .img-wrapper > .content span {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  padding-top: 2.5rem;
  font-family: 'Metropolis';
}
.event-info .img-wrapper > img,
.event-info .img-wrapper > .content {
  transition: 200ms ease-in-out;
}
.event-info .img-wrapper > .content {
  opacity: 0;
}
.event-info .img-wrapper:hover > .content.fade {
  opacity: 1;
  cursor: pointer;
}
.event-info .img-wrapper:hover > img.blur {
  filter: blur(2px);
}
@media only screen and (max-width: 481px) {
  .event-info {
    width: 35vw !important;
  }
  .event-info .event-img {
    width: 31vw;
    height: 32vw;
  }
  .events-title {
    font-size: 3.7rem;
  }
  .event-showcase-text {
    font-size: 1.8vw;
    width: 58vw;
  }
  .slick-track {
    padding: 3.2vw 0vw;
    padding-top: 0;
  }
  .event-name {
    font-size: 12px;
    margin-bottom: 9px;
  }
}
@media only screen and (max-width: 430px) {
  .slick-prev:before,
  .slick-next:before {
    font-size: 4vw !important;
  }
}
