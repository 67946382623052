@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/metropolis.min.css');
.events-section1 {
  width: 100%;
  height: 100%;
  background-color: #28192b;
  font-size: 2rem;
  border: 1px solid #28192b;
  position: relative;
}
.hexagon1 {
  position: absolute;
  z-index: 100;
  top: -5%;
  right: 0;
  max-width: 200px;
}
.title1 {
  text-align: center;
  font-family: 'MetropolisRegular';
  letter-spacing: 3px;
  font-weight: 600 !important;
  -webkit-text-stroke-width: 2px;
  font-size: 4.5rem !important;
}
.hexagon_img1 {
  height: 120px;
}
.event-title1,
.title-1 {
  width: 100%;
  position: relative;
  padding-top: 50px;
  vertical-align: middle;
  font-family: 'Metropolis';
  letter-spacing: 0.02em;
  color: #ffffff;
}
.event-title1 {
  text-align: center;
  font-size: 3em;
  font-weight: 500;
}
.title-1 {
  width: 93%;
  display: block;
  left: 7%;
  text-align: left;
  font-size: 1.4em;
  font-weight: 500;
}
.card-slider1 {
  margin: 1.3vw 7vw 2vw 7vw;
  text-align: center;
  font-family: 'Metropolis';
}
.slick-prev1 {
  left: -5vw !important;
}
.slick-next1 {
  right: -3.7vw !important;
}

@media screen and (max-width: 400px) {
  .title-1 {
    font-size: 3.7rem !important;
  }
}
