.team-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: #f6f9fd;
  color: #ff5148;
  font-family: 'MetropolisRegular';
  position: relative;
}
.stylish-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  text-align: center;
  font-size: 4rem;
  background: -webkit-linear-gradient(#ff5148, #ad201a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3vw;
  text-transform: uppercase;
}
.team-section .heading {
  font-size: 4.5rem;
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 50px;
  cursor: pointer;
}

.team-section .heading-sm {
  font-size: 2rem;
  margin-bottom: 10px;
  cursor: default;
  font-weight: normal;
}

.teams-container {
  width: 100%;
  height: 100%;
  padding: 0 2rem;
}
@media (max-width: 400px) {
  .team-section .heading {
    font-size: 3.7rem;
  }
  .team-section .heading-sm {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 481px) {
  .stylish-title {
    font-size: 3.2vw;
  }
}
.team {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.team-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #21192b;
}
.team-grid {
  /* border: 2px solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}
.team-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 320px;
  margin: 1rem;
  background: #21192b12;
  padding: 2rem 2rem 0 2rem;
  border-radius: 18px;
  /* border: 1px solid red; */
}
.profile-about-name {
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #21192b;
}

.profile-about-description {
  font-size: 1.3rem;
  font-weight: 400;
  color: #21192b;
}
.profile-about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0;
  /* border: 1px solid green; */
  width: 100%;
  padding: 0.4rem;
}
.profile-image-container {
  border: 1px solid black;
  background: #21192b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 210px;
  border-radius: 18px;
}
.profile-image {
  width: 190px;
  height: 190px;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px;
}
.adjust-right-image {
  object-position: right center;
}

hr {
  color: #21192b;
}

@media (width <=481px) {
  .team-grid {
    justify-content: center;
  }
  .team-title {
    text-align: center;
    font-size: 1.9rem;
  }
  .team-profile-card {
    width: 200px;
    height: 250px;
  }
  .profile-image-container {
    width: 180px;
    height: 170px;
  }
  .profile-image {
    width: 150px;
    height: 150px;
  }
}
