#about {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 6rem;
  padding-top: 4rem;
  font-family: 'Metropolis';

  overflow: hidden;
}

#about::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: -1%;
  z-index: -1;
  background: #342b3f;
}

.learn-more-btn {
  width: 180px;
  padding: 1rem 2.5rem;
}

a.learn-more-btn:hover {
  color: white !important;
}

.about-flex-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5rem;
}

.description {
  text-align: justify;
  margin-bottom: 3rem;
  font-size: 1.5em;
  font-family: 'MetropolisRegular', sans-serif;
  font-weight: 400;
}
.about-left {
  width: 50%;
}

.about-ul {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.about-ul li {
  font-size: 1.8rem;
  display: inline-block;
}
.about-ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: white; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.about-left span.heading {
  font-size: 9.3em;
  font-weight: bold;
  line-height: 30px;
  text-align: justify;
  font-family: 'MetropolisRegular', sans-serif;
  font-weight: 800;
}
.about-left span.heading span {
  font-weight: bold;
}

.about-right img {
  width: 100%;
  max-width: 548px;
}

.fancy-red-circles {
  /* border: 3px solid red; */
  position: absolute;
  width: 100%;
  bottom: -5%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  display: none;
}
.fancy-red-circles .fancy-red-circle {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  margin: 1rem 0;
  z-index: inherit;
}
.circles-left,
.circles-right {
  position: relative;
}
.red-circle-1 {
  position: absolute;
  bottom: 0;
}
.fancy-red-circle.red-circle-2 {
  position: absolute;
  max-width: 307px;
  max-height: 307px;
  bottom: 5%;
  border: 15px solid #ff5148;
  /* background-color: red; */
  transform: translate(-60%, 60%);
}

.fancy-red-circle.red-circle-1 {
  position: absolute;
  max-width: 216px;
  max-height: 216px;
  border: 20px solid #c32b24;
  transform: translate(-60%, 60%);
}

.fancy-red-circle.red-circle-3 {
  position: absolute;
  max-width: 505px;
  max-height: 505px;
  bottom: 0;
  border: 20px solid #ff5148;
  transform: translate(-30%, 55%);
}
.fancy-red-circle.red-circle-4 {
  position: absolute;
  max-width: 355px;
  max-height: 355px;
  border: 25px solid #c32b24;
  transform: translate(-20%, 55%);
  bottom: 0;
}

svg#separator {
  z-index: auto;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 750px) {
  .fancy-red-circle.red-circle-1 {
    width: 55px;
    height: 55px;
    border: 15px solid #c32b24;
    transform: translate(-50%, -30%);
  }
  .fancy-red-circle.red-circle-2 {
    width: 85px;
    height: 85px;
    border: 10px solid #ff5148;
    transform: translate(-40%, -10%);
  }
  .fancy-red-circle.red-circle-3 {
    width: 140px;
    height: 140px;
    border: 15px solid #ff5148;
    transform: translate(-60%, 20%);
  }
  .fancy-red-circle.red-circle-4 {
    width: 95px;
    height: 95px;
    border: 20px solid #c32b24;
    transform: translate(-50%, 20%);
  }
}

@media screen and (width <= 1248px) {
  .about-flex-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
  .about-left {
    width: 70%;
  }

  .about-right img {
    width: 100%;
    max-width: 216px;
    object-fit: cover;
  }
  .about-left span.heading {
    font-size: 1.75em;
    line-height: 30px;
  }
}

@media screen and (width <= 472px) {
  .about-left {
    width: 85%;
  }
  .about-left span.heading {
    font-size: 1.45em;
    line-height: 20px;
  }
}
@media screen and (width <= 390px) {
  .about-left {
    width: 90%;
  }
  .about-left span.heading {
    font-size: 1.45em;
    line-height: 20px;
  }
}

/* disabled red circles */
@media screen and (width <= 700px) {
  .fancy-red-circles .fancy-red-circle {
    display: none;
  }
}

/* decrease image about img size  */
@media screen and (width <= 838px) {
  .about-right > img {
    width: 100%;
    margin-top: 1.5em;
  }
}
