.judges {
  border: 1px solid #28192b;
  width: 100%;
  height: 100%;
  font-family: 'Metropolis';
  background: #28192b;
}
.judges__title {
  font-family: 'Metropolis';
  font-weight: 800;
  text-align: center;
  font-size: 4rem;
  /* background: -webkit-linear-gradient(#ff5148, #ad201a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: white;
  margin-top: 5vw;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.judge__name {
  color: #ff5148;
  font-size: 2.2rem;
  text-transform: none;
  font-weight: 800;
  margin: 10px 0;
  cursor: pointer;
  font-family: 'Metropolis';
}

.judge__position {
  font-family: 'Metropolis';
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
  text-transform: none;
  /* border: 1px solid WHITE; */
  color: white;
  width: 100%;
  text-align: center;
}

.judge__company {
  font-size: 1.4rem;
  margin: 0;
  color: white;
  text-transform: uppercase;
}

.judges-grid {
  /* border: 1px solid green; */
  max-width: 70%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 1rem;
  gap: 2rem;
}

.judges__card {
  /* border: 1px solid yellow; */
  width: 260px;
  height: 380px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 15px;
  background: #21192b1a;
  margin: 0 auto;
  /* text-align: center; */
}

.judges__card__top {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  margin-bottom: 1.8rem;
  border-radius: 15px;
  /* background: radial-gradient(circle at top, white 0, #bebebe, #8b8b8b 100%); */
}

.judge__image {
  width: 100%;
}

.judge__image img {
  width: 100%;
  object-fit: contain;
}

.judges__card__bottom {
  display: flex;
  flex-direction: column;
  /* border: 1px solid brown; */
  width: 100%;
  justify-content: center;
  align-items: center;
}

.judge__span {
  font-weight: bold;
}
