.prizes-section {
  text-align: center;
  background-color: #28192b;
  /* background-color: white; */
  font-size: 2rem;
  position: relative;
  padding-top: 12rem;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.prizes-title {
  color: #fff;
  font-size: 4.5rem;
  text-transform: uppercase;
  font-weight: 800;
  margin: 10px 0;
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.prizes-description {
  color: white;
  padding: 2rem;
  width: 100%;
}
.prizes-description p {
  margin-bottom: 5rem;
  text-transform: none;
  font-family: 'Metropolis';
}
.prizes-description span {
  border: 1px solid red;
  display: inline-block;
  padding: 1.5rem;
  text-transform: none;
  background: linear-gradient(
    131.14deg,
    rgba(255, 81, 72, 0.12) 0%,
    rgba(255, 81, 72, 0) 100%
  );
  border: 2px solid #ff5148;
  border-radius: 10px;
  font-family: 'Metropolis';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.red {
  font-size: 50px;
  background: none !important;
}
.white {
  color: white;
}
.red-1 {
  color: red;
  background: linear-gradient(180deg, #ff5148 0%, rgba(255, 81, 72, 0.63) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
}
.card-inline {
  display: inline-flex;
  position: relative;
  margin: 15px;
}

@media (max-width: 400px) {
  .prizes-title {
    font-size: 3.7rem !important;
  }
}
