.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 90px;
  background-color: #28192b;
  padding: 0 2rem;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  backdrop-filter: blur(6px) saturate(200%);
  -webkit-backdrop-filter: blur(6px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.76);
}
.logo {
  flex: 1;
}
.header .nav {
  flex: 6;
  position: relative;
  /* border: 1px solid green; */
}

.header .nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  padding-left: 0;
}
.nav ul li {
  margin: 2rem;
  font-size: 1.5rem;
  font-family: 'Metropolis';
  white-space: nowrap;
}
.nav ul li a {
  text-decoration: none;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-weight: 400;
}
.nav ul li a:hover {
  color: #fd5047;
}
.nav ul li a.sticky-link {
  color: #fd5047;
}
.nav ul li a.sticky-link:hover {
  color: rgba(38, 38, 38, 0.824);
}
.college-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.nav .hamburger {
  display: none;
}

@media (max-width: 850px) {
  .header {
    flex-direction: column;
  }
  .nav .hamburger {
    display: inline-flex;
    z-index: 10;
    position: absolute;
    top: -10px;
    left: 0;
  }

  .header .nav ul {
    flex-direction: column;
    justify-content: flex-start;
  }
  .nav ul li {
    margin: 2rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 500px) {
  .header .logo {
    flex: 1;
  }
  .header .nav {
    flex: 2;
  }
  .header .nav ul {
    justify-content: flex-start;
  }
  .college-images {
    flex: 2;
  }
}
