@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1170px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footer {
  background-color: #f6f9fd;
  padding: 70px 0;
  position: relative;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #000d1b;
  text-transform: capitalize;
  margin-bottom: 30px;
  margin-left: 23px;
  font-weight: 500;
  position: relative;
  cursor: default;
  color: 'black';
  font-weight: 700;
  /* border: 1px solid green; */
}
.footer-col h4#cws-h4 {
  margin-left: 15px;
}
/* .footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ff5148;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
} */

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #24262b;
  text-decoration: none;
  font-weight: 300;
  color: #24262b;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ff5148;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #24262b;
  transition: all 0.1s ease-in-out;
}

.footer-col .social-links a:hover {
  color: #ff5148;
}
.copyright-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28192b;
  color: #ff5148;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30px;
  font-size: 1.23rem;
}
.copyright-text p {
  margin-bottom: 0;
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
