@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/metropolis.min.css');
.Event-card1 {
  height: 480px;
  width: 260px;
  background-color: #fbfdff1a;
  border-radius: 12.1968px;
  padding-top: 0.5rem;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start; */
}
.Event-card1 img {
  object-fit: contain;
  object-position: top center;
}
.rectangle1 {
  height: 140px;
  width: 260px;
  border-radius: 12.1968px;
  padding: 9px 9px 0px 9px;
}
.line-1,
.line-2-name1 {
  color: #ffffff;
  font-family: 'Metropolis';
  margin-left: 10px;
}

.line-1 {
  margin: 10px 0px 18px 10px;
  width: 90%;
  text-align: left;
  /* border: 1px solid red; */
  padding-left: 10px;
}
.light-gray1 {
  color: #87838c;
  text-align: left;
  margin: 10px 0px 0px 10px;
}
.line-2-name1,
.light-gray1 {
  font-size: 0.9em;
  margin-top: 9px;
}
#date {
  padding-left: 10px;
  /* border: 1px solid red; */
  height: 50px;
}

.red-btn1 {
  background: #de4139;
  color: #ffffff;
  height: 30px;
  width: 80%;
  font-family: 'Metropolis';
  margin: 0px 20px;
  border: none;
  border-radius: 4.69185px;
}

#register-now:hover {
  background: #cb3a33;
}
