* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 600px) {
  #section-title {
    font-weight: 300;
    font-size: 4.4rem !important;
  }
  #section-title1 {
    font-weight: 300;
    font-size: 4.4rem !important;
  }
  #section-title2 {
    font-weight: 300;
    font-size: 4.4rem !important;
  }
}
