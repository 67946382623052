.header2 .navbar2 ul li a:hover {
  color: #ff5148;
  opacity: 1;
}

.header2 .navbar2 ul li a {
  opacity: 0.6;
}

.header-content2 > p {
  font-family: 'Aqua Grotesque', sans-serif;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 500px) {
  .LDRP-header {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }
}
