body {
  background: #f6f9fd;
}
.sponsors {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  /* align-items: center; */
  width: 100%;
  /* height: 100%; */
  background: #f6f9fd;
  font-family: 'Metropolis';
  /* margin-bottom: 16.4rem; */
  position: relative;
  /* border: 1px solid red; */
  padding: 1.5rem;
  padding-bottom: 12rem;
}
.sponsors::before {
  content: '';
  position: absolute;
  bottom: 110%;
  z-index: -1;
  width: 100%;
  height: 50px;
  background: #342b3f;
}
.sponsors .heading {
  color: #ff5148;
  font-size: 4.5rem;
  text-transform: uppercase;
  font-weight: 800;
  margin: 10px 0;
  cursor: pointer;
}
.stylish-title {
  font-family: 'Metropolis';
  font-weight: 800;
  text-align: center;
  font-size: 4rem;
  background: -webkit-linear-gradient(#ff5148, #ad201a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3vw;
  text-transform: uppercase;
}
.sponsors .heading-sm {
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;
  cursor: default;
  font-weight: normal;
}

.sponsors-cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  margin-bottom: 1.5rem;
}
.sponsor-image img {
  width: 100%;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
}
/* .sponsor-image img:hover {
  -webkit-filter: none;
  filter: none;
} */
.sponsor-image {
  width: 100%;
  height: 100%;
  padding: 3rem;
}
.sponsor-card {
  margin: 5rem;
  padding: 4rem 2rem;
  width: 17rem;
  height: 20rem;
  border-radius: 30px;
  cursor: pointer;
  background: #f6f9fd;
  box-shadow: 13px 13px 33px rgba(255, 81, 72, 0.1),
    inset 14px 14px 33px rgba(255, 81, 72, 0.1);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  z-index: 1000000;
}
.sponsor-card:hover {
  box-shadow: 13px 13px 33px rgba(255, 81, 72, 0.35),
    inset 14px 14px 33px rgba(255, 81, 72, 0.35);
  -webkit-filter: none;
  filter: none;
}

.sponsor-name {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: Metropolis;
  color: rgba(255, 81, 72, 1);
  font-size: 2.2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Metropolis';
  line-height: 25px;
}

.sponsor-levels {
  display: inline-flex;
  overflow: hidden;
  border-radius: 12px;
  background: #f23a3a0f;
  font-size: 1.3rem;
  font-family: 'Metropolis';
  text-transform: uppercase;
}
.sponsor-levels__input {
  display: none;
}
.sponsor-levels__label {
  padding: 1rem 1.5rem;
  background: rgba(242, 58, 58, 0.06);
  font-size: 1.7rem;
  cursor: pointer;
  transition: background 0.1s;
  color: #ff514899;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.sponsor-levels__input:checked + .sponsor-levels__label {
  background-color: #ff5148;
  color: #fcfcfc;
}

/* sponsors revamp start */
.sponsors-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* border: 1px solid red; */
  /* padding: 1rem; */
  width: 100%;
  scroll-behavior: smooth;
  margin-top: 2.5rem;
}

/* hide scrollbar but allow scrolling */
.sponsors-display {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: none;
}
/* 
.sponsors-display::-webkit-scrollbar {
  display: none; 
} */

.sponsors-display .sponsor-item {
  flex: 1;
  margin: 0.7rem 2rem;
}

.sponsor-item-image {
  object-fit: contain;
  width: 130px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.sponsor-item-image:hover {
  -webkit-filter: none;
  filter: none;
}

/* sponsors revamp end */

@media (max-width: 620px) {
  .sponsors-cards-grid {
    grid-template-columns: 1fr 1fr;
  }
  .sponsor-card {
    width: 15rem;
    height: 18rem;
  }
}
@media (max-width: 400px) {
  .sponsors-cards-grid {
    grid-template-columns: 1fr;
  }
  .sponsor-levels__label {
    font-size: 1.4rem;
  }
  .sponsors .heading {
    font-size: 3.7rem;
  }
  .sponsors .heading-sm {
    font-size: 1.5rem;
  }
}
