*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  box-sizing: border-box;
}
/*
section.Faq {
  width: 100%;
  height: 100%;
  background-color: #28192b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 1.5rem;
}
#faq .accordion-link1:hover {
  color: #ff5148;
}
.title1 {
  font-size: 30px;
  color: #f7f7f7;
  font-weight: 800 !important;
  text-align: center;
  padding-bottom: 34px;
  font-size: 4.5rem;
}

.container1 {
  width: 100%;
  max-width: 80rem;
  padding: 0 auto;
  padding: 0 1.5rem;
}

.accodion-item1 {
  background-color: #283042;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  box-shadow: 0.5rem 2px 0.5rem rgba(0, 0, 0, 0.1);
}

.accordion-link1 {
  font-size: 1.6rem;
  color: #fcfcfc;
  text-decoration: none;
  background-color: #373040;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-radius: 10px;
  margin: 10px 0px 10px;
  padding-left: 12px;
  text-transform: uppercase;
  font-family: 'Metropolis';
}

.accordion-link1 ion-icon {
  color: #e7d5ff;
  padding: 0.5rem;
  margin-right: 0.5rem;
}

.accordion-link1 .up1 {
  display: none;
}

.answer1 {
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #373040;
  transition: max-height 650ms;
  border-radius: 10px;
  font-family: 'Metropolis';
}

.answer1::before {
  content: '';
  position: absolute;
  width: 0.6rem;
  height: 90%;
  background-color: #d63737;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.answer1 p {
  color: #fcfcfc;
  font-size: 1.4rem;
  padding: 2rem;
  text-transform: none;
}

.accordion-item1:target .answer1 {
  max-height: 20rem;
  margin-left: auto;
}

.accordion-item1:target .accordion-link1 .down1 {
  display: none;
}
.accordion-item1:target .accordion-link1 .up1 {
  display: block;
}
*/

/* ----------new css--------- */

section.Faq {
  height: 100%;
  background-color: #28192b;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  padding-bottom: 1.5rem;
  /* border: 1px solid green; */
}

#faq p a {
  color: #ff5148;
}

#faq .unique-answer {
  text-transform: initial;
}

#faq .faq-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  font-family: 'Metropolis';
  margin: 0 auto;
}
.faq-container .faq-item {
  background-color: rgba(52, 43, 63, 1);
  /* border-radius: 2rem; */
  margin-bottom: 1rem;
  box-shadow: 0.5rem 2px 0.5rem rgba(0, 0, 0, 0.1);
  margin: 1rem;
  max-width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
  /* flex: 1; */
  border-radius: 10px;
  cursor: pointer;
}

.faq-item .faq-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  padding: 1rem;
  /* padding-top: 0; */
  padding-bottom: 0;
  max-width: 100%;
  gap: 22rem;
}

.faq-question h3 {
  font-size: 1.8rem;
  color: white;
  text-transform: initial;
  font-weight: 400;
  font-family: 'Metropolis';
  width: 100%;
  /* border: 1px solid cyan; */
  height: 100%;
  /* break-inside: avoid; */
  /* white-space: nowrap; */
  padding: 1.5rem;
}

.faq-question h3:hover {
  color: #ff5148;
}

.faq-item p {
  /* border: 1px solid yellow; */
  padding: 1.5rem;
  margin: 1rem 0;
  font-size: 1.6rem;
  color: white;
  text-transform: initial;
  text-align: justify;
  line-height: 1.3;
  max-width: 100%;
  word-wrap: break-all;
  border-left: 7px solid #ff5148;
  min-height: 50px;
}

.faq-container h3#faq-title {
  font-size: 6.5rem;
  color: white;
  margin: 1rem;
  font-weight: 900;
  font-family: 'Metropolis';
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 650px) {
  .faq-question h3 {
    font-size: 1.5rem;
    flex: 3;
  }
  .faq-question {
    justify-content: flex-start;
    gap: 5rem;
  }

  .faq-item p {
    font-size: 1.3rem;
  }
}
